// Polyfills
// eslint-disable-next-line no-self-compare
Number.isNaN = Number.isNaN || (value => value !== value);

export default class App {
  constructor() {
    this.rootElement = document.querySelector('.js-app-root');
    this.registeredViews = [];
  }

  start() {
    this.loadFonts();
    this.lazyLoadImages();
    this.initializeModals();
    this.initializeAppLoader();
    this.initializeSmoothScroll();
    this.initializeAnalyticsAndTrackers();
    this.initializeiFrameLoader();
  }

  // eslint-disable-next-line class-methods-use-this
  loadFonts() {
    if (window.clioFontsLoaded) {
      return;
    }

    import(/* webpackChunkName: 'fontfaceobserver' */ 'fontfaceobserver')
      .then((module) => {
        const FontFaceObserver = module.default;

        if (!window.clioIsFontLoaded(window.CLIO_FONTS.openSans)) {
          Promise.all([
            new FontFaceObserver('Open Sans', { weight: 400, style: 'normal' }),
            new FontFaceObserver('Open Sans', { weight: 600, style: 'normal' }),
            new FontFaceObserver('Open Sans', { weight: 400, style: 'italic' }),
          ]).then(() => window.clioSetFontLoaded(window.CLIO_FONTS.openSans)).catch(() => {});
        }

        if (!window.clioIsFontLoaded(window.CLIO_FONTS.sourceSansPro)) {
          Promise.all([
            new FontFaceObserver('Source Sans Pro', { weight: 400, style: 'normal' }),
            new FontFaceObserver('Source Sans Pro', { weight: 600, style: 'normal' }),
            new FontFaceObserver('Source Sans Pro', { weight: 400, style: 'italic' }),
          ]).then(() => window.clioSetFontLoaded(window.CLIO_FONTS.sourceSansPro)).catch(() => {});
        }

        if (!window.clioIsFontLoaded(window.CLIO_FONTS.poppins)) {
          Promise.all([
            new FontFaceObserver('Poppins Text A', { weight: 600, style: 'normal' }),
          ]).then(() => window.clioSetFontLoaded(window.CLIO_FONTS.poppins)).catch(() => {});
        }

        if (
          !window.clioIsFontLoaded(window.CLIO_FONTS.sourceSerif4)
          && window.site.page.is_enterprise_section
        ) {
          Promise.all([
            new FontFaceObserver('Source Serif 4', { weight: 500, style: 'normal' }),
          ]).then(() => window.clioSetFontLoaded(window.CLIO_FONTS.sourceSerif4)).catch(() => {});
        }
      })
      .catch(() => {});
  }

  loadMarketoFormsApi() {
    if (this.loadedMarketoFormsApi) {
      return;
    }

    this.loadedMarketoFormsApi = true;

    import(/* webpackChunkName: 'marketoFormsLoader' */ '../shared/loaders/marketo-forms')
      .then(marketoFormsLoaderModule => marketoFormsLoaderModule.default.load())
      .catch(() => {});
  }

  // eslint-disable-next-line class-methods-use-this
  lazyLoadImages() {
    if ('loading' in HTMLImageElement.prototype) {
      // Native lazy loading supported
      this.rootElement.querySelectorAll('.js-lazy-image-wrapper').forEach((lazyImageWrapper) => {
        // Update the srcset for <source> elements in the <picture>
        lazyImageWrapper.querySelectorAll('source').forEach((source) => {
          /* eslint-disable no-param-reassign */
          const srcset = source.getAttribute('data-srcset');
          if (srcset) {
            source.setAttribute('srcset', srcset);
          }
          /* eslint-enable no-param-reassign */
        });

        // Update the src and srcset for <img> element in the <picture>
        lazyImageWrapper.querySelectorAll('img').forEach((image) => {
          /* eslint-disable no-param-reassign */
          image.onload = () => {
            image.className = `${image.className} lazyloaded`;
          };

          const srcset = image.getAttribute('data-srcset');
          if (srcset) {
            image.setAttribute('srcset', srcset);
          }

          const src = image.getAttribute('data-src');
          if (src) {
            image.src = src;
          }
          /* eslint-enable no-param-reassign */
        });
      });
    } else {
      // Use LazySizes since the browser does not support native lazy loading
      window.lazySizesConfig = window.lazySizesConfig || {};
      window.lazySizesConfig.lazyClass = 'js-lazy-image';

      import(/* webpackChunkName: 'lazysizes' */ 'lazysizes').catch(() => {});
    }
  }

  // eslint-disable-next-line class-methods-use-this
  initializeModals() {
    if (!document.querySelectorAll('[data-lity]').length) {
      return;
    }

    import(/* webpackChunkName: 'modalController' */ './modal-controller').catch(() => {});
  }

  // eslint-disable-next-line class-methods-use-this
  initializeSmoothScroll() {
    if (!document.querySelectorAll('a[data-smooth-scroll]').length) {
      return;
    }

    import(/* webpackChunkName: 'smoothScrollController' */ './smooth-scroll-controller').catch(() => {});
  }

  // eslint-disable-next-line class-methods-use-this
  initializeAnalyticsAndTrackers() {
    import(/* webpackChunkName: 'marketingCookieTracker' */ '../shared/tracker/cookies')
      .then(MarketingCookieTrackerModule => MarketingCookieTrackerModule.capture())
      .catch(() => {});

    import(/* webpackChunkName: 'analytics' */ '../shared/analytics')
      .then(AnalyticsModule => AnalyticsModule.initialize())
      .catch(() => {});
  }

  // eslint-disable-next-line class-methods-use-this
  initializeAppLoader() {
    import(/* webpackChunkName: 'appLoader' */ './loader')
      .then((appLoaderModule) => {
        const AppLoader = appLoaderModule.default;
        this.registerViews(AppLoader);
        return AppLoader.load();
      })
      .catch(() => {});
  }

  // eslint-disable-next-line class-methods-use-this
  initializeiFrameLoader() {
    if (!document.querySelectorAll('.js-iframe-loader').length) {
      return;
    }

  import(/* webpackChunkName: 'iframeLoader' */ './loader/iframe-loader')
    .then((iframeLoaderModule) => {
      const IframeLoader = iframeLoaderModule.default;
      return IframeLoader.load();
    })
    .catch(() => {
    });
  }

  registerViews(AppLoader) {
    // Common to all pages: CRO
    AppLoader.registerView('cro', [this.rootElement], 0);

    // Common to all pages: General
    AppLoader.registerView('analytics', [this.rootElement], 0);
    AppLoader.registerView('header', this.rootElement.querySelectorAll('.js-header-container'), 0);
    AppLoader.registerView('masthead', this.rootElement.querySelectorAll('.js-masthead'), 0);

    AppLoader.registerView('ctas/banner', this.rootElement.querySelectorAll('.js-banner'), 1500);
    AppLoader.registerView('ctas/gated', this.rootElement.querySelectorAll('.js-gated-resource'), 0);
    AppLoader.registerView('ctas/exit-modal', this.rootElement.querySelectorAll('.js-exit-intent-modal'), 0);
    AppLoader.registerView('follow-up-modal', this.rootElement.querySelectorAll('.js-follow-up-modal'), 0);

    // Common to all pages: Forms
    AppLoader.registerView('form', this.rootElement.querySelectorAll('.js-form'), 0);
    AppLoader.registerView('signup-form', this.rootElement.querySelectorAll('.js-signup-form'), 0);
    AppLoader.registerView('marketo-form', this.rootElement.querySelectorAll('.js-marketo-form'), 0);

    // Common to all pages: Inputs
    AppLoader.registerView('inputs/number', this.rootElement.querySelectorAll('.js-input-number'), 0);
    AppLoader.registerView('inputs/range', this.rootElement.querySelectorAll('.js-input-range'), 0);
    AppLoader.registerView('inputs/radio', this.rootElement.querySelectorAll('.js-input-radio'), 0);

    // Common to all pages: Objects
    AppLoader.registerView('loader', this.rootElement.querySelectorAll('.js-loader'));
    AppLoader.registerView('tooltips', this.rootElement.querySelectorAll('.js-load-tippy'));
    AppLoader.registerView('cta', this.rootElement.querySelectorAll('.js-cta'));
    AppLoader.registerView('lottie-animation', this.rootElement.querySelectorAll('.js-lottie-animation'), 0);

    // Common to all pages: Components
    AppLoader.registerView('dynamic-content', this.rootElement.querySelectorAll('.js-dynamic-content'));
    AppLoader.registerView('payments-calculator', this.rootElement.querySelectorAll('.js-payments-calculator'));
    AppLoader.registerView('roi-calculator', this.rootElement.querySelectorAll('.js-roi-calculator'));
    AppLoader.registerView('video', this.rootElement.querySelectorAll('.js-video'));
    AppLoader.registerView('accordion', this.rootElement.querySelectorAll('.js-accordion'));
    AppLoader.registerView('company-values-carousel', this.rootElement.querySelectorAll('.js-company-values-carousel'));
    AppLoader.registerView('logo-carousel', this.rootElement.querySelectorAll('.js-logo-carousel'));
    AppLoader.registerView('company-timeline', this.rootElement.querySelectorAll('.js-company-timeline'));
    AppLoader.registerView('company-teams', this.rootElement.querySelectorAll('.js-company-teams'));
    AppLoader.registerView('graph', this.rootElement.querySelectorAll('.js-graph'));
    AppLoader.registerView('gallery', this.rootElement.querySelectorAll('.js-gallery'));
    AppLoader.registerView('comparison-table-accordion', this.rootElement.querySelectorAll('.js-comparison-accordion'));
    AppLoader.registerView('featured-resources-carousel', this.rootElement.querySelectorAll('.js-resources-carousel'));
    AppLoader.registerView('grow-calculator', this.rootElement.querySelectorAll('.js-grow-calculator'));
    AppLoader.registerView('tabber', this.rootElement.querySelectorAll('.js-tabber'));
    AppLoader.registerView('multi-step-tool', this.rootElement.querySelectorAll('.js-multi-step-tool'));
    AppLoader.registerView('seo-friendly-tabber', this.rootElement.querySelectorAll('.js-seo-friendly-tabber'), 0);

    // Common to all pages: Job board
    AppLoader.registerView('job-board', this.rootElement.querySelectorAll('.js-job-board'));
    AppLoader.registerView('job-masthead-filters', this.rootElement.querySelectorAll('.js-masthead-job-filters'));

    // Common to all pages: Regional
    AppLoader.registerView('region-modal', this.rootElement.querySelectorAll('.js-region-modal'), 2000);
    AppLoader.registerView('region-selector', this.rootElement.querySelectorAll('.js-region-selector'));

    // Common to all pages: Cookies
    AppLoader.registerView('cookie-consent-modal', this.rootElement.querySelectorAll('.js-cookie-consent-modal'), 0);
  }
}
